import React from "react";
import styles from "./PageContainer.module.scss";

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default PageContainer;
