import React from "react";
import useGameStore, { Game } from "../../../store/game";
import { useTranslation } from "react-i18next";
import MinMaxNumberInput from "../../../components/MinMaxNumberInput";
import Button from "../../../components/Button";

interface JoinGameProps {
  game: Game;
}

const JoinGame: React.FC<JoinGameProps> = ({ game }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<number | null>(null);
  const setSecondChosenNumber = useGameStore(
    (state) => state.setSecondChosenNumber
  );
  const { max } = game;

  const onContinuePress = () => {
    if (value === null) return;
    setSecondChosenNumber(value, game.id);
  };

  const canContinue = value !== null && value >= 1 && value <= game.max;

  return (
    <>
      <h1>{t("oneOnTen", { max })}</h1>
      <p>{t("gamePage.joinGame.explanation", { max })}</p>
      <MinMaxNumberInput
        min={1}
        max={max}
        onNumberChange={setValue}
        randomNumberPlaceholder={true}
      />
      <Button
        text={t("ui.continue")}
        disabled={!canContinue}
        onClick={onContinuePress}
      />
    </>
  );
};

export default JoinGame;
