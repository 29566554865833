import React from "react";
import styles from "./ContentBox.module.scss";

interface ContentBoxProps {
  children: React.ReactNode;
  topBar?: React.ReactNode;
  bottomBar?: React.ReactNode;
  contentClassName?: string;
  showInfoButton?: boolean;
}

const ContentBox: React.FC<ContentBoxProps> = ({
  children,
  topBar,
  bottomBar,
  contentClassName = "",
}) => {
  return (
    <div className={styles.container}>
      {topBar}
      <div className={`${styles.content} ${contentClassName}`}>{children}</div>
      {bottomBar}
    </div>
  );
};

export default ContentBox;
