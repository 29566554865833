import React, { useState } from "react";
import styles from "./index.module.scss";
import PageContainer from "../../components/PageContainer";
import { useTranslation } from "react-i18next";
import { MdInfoOutline } from "react-icons/md";
import ContentBox from "../../components/ContentBox";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import useGameStore from "../../store/game";
import ErrorText from "../../components/ErrorText";
import MinMaxNumberInput from "../../components/MinMaxNumberInput";
import { BsPencilSquare } from "react-icons/bs";
import ChangeMax from "./components/ChangeMax";
import useToggle from "../../hooks/useToggle";

const MainPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState<number | null>(null);
  const [showChangeMax, toggleShowChangeMax] = useToggle(false);
  const { createGame, loading, error, max } = useGameStore();

  const onContinuePress = async () => {
    if (value === null) return;
    await createGame(value, () => navigate("/share-game"));
  };

  const buttonEnabled = value !== null && value > 0 && value <= max;

  return (
    <PageContainer>
      <ContentBox
        bottomBar={
          <div className={styles.contentBoxBarWrapper}>
            <Link to="/info">
              <MdInfoOutline size="24" />
            </Link>
          </div>
        }
      >
        <div className="row">
          <h1 className={styles.title}>{t("oneOnTen", { max })}</h1>
          <div className="relative">
            <BsPencilSquare
              className={styles.changeIcon}
              size="24"
              onClick={toggleShowChangeMax}
            />
            {showChangeMax && <ChangeMax onClose={toggleShowChangeMax} />}
          </div>
        </div>
        <h3 className={styles.title}>{t("whatsYourGuess")}</h3>

        <MinMaxNumberInput
          min={1}
          max={max}
          onNumberChange={setValue}
          value={value === null ? "" : value}
          randomNumberPlaceholder
        />

        <Button
          text={t("ui.continue")}
          disabled={!buttonEnabled}
          onClick={onContinuePress}
          loading={loading}
        />

        <ErrorText text={error} />
      </ContentBox>
    </PageContainer>
  );
};

export default MainPage;
