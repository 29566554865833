import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import resources from "./locale";
import useGameStore from "./store/game";
import InfoPage from "./pages/info-page";
import ShareGamePage from "./pages/share-game-page";
import GamePage from "./pages/game-page/GamePage";
import MainPage from "./pages/main-page";

i18n.use(initReactI18next).init({
  resources,
  lng: "nl",
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const { t } = useTranslation();
  const { reset } = useGameStore();

  useEffect(() => {
    reset();
    document.title = t("title");
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/info" element={<InfoPage />} />
        <Route path="/share-game" element={<ShareGamePage />} />
        <Route path="/game/:gameId" element={<GamePage />} />
        <Route path="/*" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;
