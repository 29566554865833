const nl = {
  title: "Kans 1 op 10",
  oneOnTen: "Kans 1 op {{max}}!",
  whatsYourGuess: "Kies jouw getal!",
  ui: {
    continue: "Verder",
    goBack: "Terug",
    tryAgainLater: "Probeer het later opnieuw!",
    unknownError: "Er is een onbekende fout opgetreden!",
    apply: "Toepassen",
    again: "Opnieuw",
  },
  information: {
    title: "Informatie",
    howDoesItWork: "Hoe werkt het?",
    bulletPoints: [
      "Kies een getal tussen 1 t/m 10",
      "Ga verder en stuur de deellink naar je vrienden",
      "De ander kiest ook een getal",
      "Zie of jullie hetzelfde getal hadden!",
    ],
  },
  mainPage: {
    couldNotCreateGame: "Kon het spel niet aanmaken",
  },
  shareLink: {
    title: "Link delen!",
    noGame: "Er is geen spel gevonden",
    createNewGame: "Maak een nieuw spel aan",
    pressButton:
      "Stuur de deellink naar je vrienden zodat ze het spel kunnen spelen",
    share: "Deel link!",
    shareText:
      "Klik op de deellink om Kans 1 op {{max}} te spelen. Kies een getal tussen 1 t/m {{max}} en kijk of jullie hetzelfde getal hadden!",
    copyExplanation: "Klik op de knop om de link te kopieren",
    copy: "Kopieer",
    copied: "Gekopieerd!",
    error: "Kan de link niet delen",
    goToMain: "Opnieuw",
    tip: "Tip: Open de link zelf om het resultaat te zien",
  },
  gamePage: {
    title: "Meedoen aan spel",
    gameNotFound: "Er is geen spel gevonden, probeer het later opnieuw",
    loading: "Laden...",
    notFound: "Het spel is niet gevonden",
    joinGame: {
      explanation: "Gok een ander getal tussen 1 t/m {{max}} dan de ander!",
    },
    result: "Resultaat",
    share: "Deel resultaat",
    shareText: "Dit is het resultaat van kans 1 op {{max}}!",
  },
};

export default nl;
