import React from "react";
import styles from "./ErrorText.module.scss";

interface ErrorTextProps {
  text?: string | string[];
  error?: boolean;
}

const ErrorText: React.FC<ErrorTextProps> = ({ text, error = true }) => {
  if (!error || !text) return <></>;

  return typeof text === "string" ? (
    <p className={styles.text}>{text}</p>
  ) : (
    <>
      {text.map((text, index) => (
        <p key={index} className={styles.text}>
          {text}
        </p>
      ))}
    </>
  );
};

export default ErrorText;
