import React, { useState } from "react";
import styles from "./Input.module.scss";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  initialValue?: string;
}

const Input: React.FC<InputProps> = (props) => {
  const { initialValue, onChange } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (!!onChange) onChange(e);
  };

  return (
    <input
      value={value}
      {...props}
      onChange={handleChange}
      className={`${styles.input} ${props.className || ""}`}
    />
  );
};

export default Input;
