import React from "react";
import useGameStore from "../../../store/game";
import JoinGame from "./JoinGame";
import GameResult from "./GameResult";

const ShowGame: React.FC = () => {
  const { currentGame: game } = useGameStore();

  if (!game) return null;
  if (game.secondChosenNumber === null) return <JoinGame game={game} />;
  return <GameResult game={game} />;
};

export default ShowGame;
