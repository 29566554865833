import React, { useEffect, useRef, useState } from "react";
import { Game } from "../../../store/game";
import styles from "./GameResult.module.scss";
import { useTranslation } from "react-i18next";
import helpers from "../../../helpers";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";

interface GameResultProps {
  game: Game;
}

const { getRandomNumber } = helpers.numbers;

interface RandomNumberCount {
  number: number;
  count: number;
}

const GameResult: React.FC<GameResultProps> = ({ game }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [firstRandom, setFirstRandom] = useState<RandomNumberCount>({
    number: getRandomNumber(1, game.max),
    count: 0,
  });
  const [secondRandom, setSecondRandom] = useState<RandomNumberCount>({
    number: getRandomNumber(1, game.max),
    count: 0,
  });
  const resultContainerRef = useRef<HTMLDivElement>(null);
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);

  const randomNumbersLoop = (timeout: number, side: "first" | "second") => {
    setTimeout(() => {
      const setter = side === "first" ? setFirstRandom : setSecondRandom;
      if (timeout < 500) {
        setter((prev) => ({
          number: getRandomNumber(1, game.max),
          count: prev.count + 1,
        }));
        randomNumbersLoop(
          timeout + timeout / 5,
          side === "first" ? "second" : "first"
        );
      } else {
        setAnimationFinished(true);
      }
    }, timeout);
  };

  const goToMainPage = () => {
    navigate("/");
  };

  const shareResult = async () => {
    if (!resultContainerRef.current) return;
    const canvas = await html2canvas(resultContainerRef.current);
    const image = canvas.toDataURL("image/png");
    const response = await fetch(image);
    const filesArray = [
      new File([await response.blob()], "result.png", { type: "image/png" }),
    ];
    const shareData = {
      title: t("gamePage.shareText", { max: game.max }),
      files: filesArray,
    };
    navigator.share(shareData);
  };

  useEffect(() => {
    randomNumbersLoop(50, "first");
  }, []);

  const additionalBoxClassName = !animationFinished
    ? styles.flashAnimation
    : game.initialChosenNumber === game.secondChosenNumber
    ? styles.equal
    : styles.different;

  return (
    <>
      <div className={styles.resultContainer} ref={resultContainerRef}>
        <h1 className={styles.title}>{t("gamePage.result")}</h1>
        <div className="row">
          <div
            key={`first-${!!firstRandom ? firstRandom.count : "result"}`}
            className={`${styles.resultBox} ${additionalBoxClassName}`}
          >
            <p>
              {animationFinished
                ? game.initialChosenNumber
                : firstRandom.number}
            </p>
          </div>
          <div
            key={`second-${!!secondRandom ? secondRandom.count : "result"}`}
            className={`${styles.resultBox} ${additionalBoxClassName}`}
          >
            <p>
              {animationFinished
                ? game.secondChosenNumber
                : secondRandom.number}
            </p>
          </div>
        </div>
      </div>
      {animationFinished && (
        <div className="col">
          {!!navigator.share && (
            <Button
              text={t("gamePage.share")}
              onClick={shareResult}
              className={styles.button}
            />
          )}
          <Button
            text={t("ui.again")}
            onClick={goToMainPage}
            className={styles.button}
          />
        </div>
      )}
    </>
  );
};

export default GameResult;
