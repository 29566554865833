import React from "react";
import styles from "./Loader.module.scss";

interface LoaderProps {
  size?: "x-small" | "small" | "medium" | "large";
  loading?: boolean;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({
  size = "medium",
  loading = true,
  className = "",
}) => {
  if (!loading) return null;
  return (
    <div
      className={`${styles.loader} ${styles["loader-" + size]} ${className}`}
    >
      <div className={styles.loader__inner} />
    </div>
  );
};

export default Loader;
