import React, { useEffect } from "react";
import Input, { InputProps } from "./Input";
import helpers from "../helpers";

interface MinMaxNumberInputProps extends Omit<InputProps, "type"> {
  min?: number;
  max?: number;
  onNumberChange: (value: number | null) => void;
  randomNumberPlaceholder?: boolean;
}

const MinMaxNumberInput: React.FC<MinMaxNumberInputProps> = (props) => {
  const [value, setValue] = React.useState<number | null>(null);
  const [placeholder, setPlaceholder] = React.useState<string>("");
  const { min, max, onNumberChange, randomNumberPlaceholder, ...rest } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setValue(null);
      onNumberChange(null);
    }
    const value = parseInt(e.target.value);
    if (
      (min === undefined || value >= min) &&
      (max === undefined || value <= max)
    ) {
      setValue(value);
      onNumberChange(Number(e.target.value));
    }
  };

  useEffect(() => {
    if (max === undefined) return;
    setPlaceholder(String(helpers.numbers.getRandomNumber(1, max)));
  }, [max]);

  return (
    <Input
      type="number"
      onChange={handleChange}
      placeholder={randomNumberPlaceholder ? placeholder : undefined}
      {...rest}
      value={value === null ? "" : value}
    />
  );
};

export default MinMaxNumberInput;
