import React from "react";
import styles from "./Button.module.scss";
import Loader from "./Loader";
import AnimatedCheckmark from "./AnimatedCheckmark";

interface ButtonProps {
  text: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  success?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  disabled = false,
  onClick,
  loading = false,
  success = false,
  className = "",
}) => {
  const handleClick = !disabled && !!onClick ? onClick : undefined;

  return (
    <button
      onClick={handleClick}
      className={`${styles.button} ${
        disabled || loading ? styles.disabled : ""
      } ${className}`}
    >
      {success && (
        <AnimatedCheckmark
          color="var(--color-primary-600)"
          className={styles.checkmark}
        />
      )}
      <Loader className={styles.loader} loading={loading} size="x-small" />
      {text}
    </button>
  );
};

export default Button;
