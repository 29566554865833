import create, { StateCreator } from "zustand";
import api from "../api";
import axios from "axios";
import i18n from "i18next";
import { devtools, persist } from "zustand/middleware";

export interface Game {
  id?: string;
  initialChosenNumber?: number;
  secondChosenNumber?: number;
  max: number;
  side?: "creator" | "joiner";
}

export interface GameStoreState {
  participatedGames: Game[];
  currentParticipatedGameId: Game["id"];
  currentGame?: Game;
  loading: boolean;
  error?: string;
  max: number;
  setMax: (max: number) => void;
  createGame: (chosenNumber: number, onSuccess: () => void) => Promise<void>;
  fetchAndStoreGame: (gameId: Game["id"]) => Promise<void>;
  setSecondChosenNumber: (
    secondChosenNumber: number,
    gameId: Game["id"]
  ) => Promise<void>;
  reset: () => void;
}

const initialState: StateCreator<GameStoreState, any> = (set, get) => ({
  participatedGames: [],
  currentParticipatedGameId: undefined,
  currentGame: undefined,
  loading: false,
  max: 10,
  setMax: (max: number) => {
    set((state) => ({ ...state, max }));
  },
  createGame: async (chosenNumber: number, onSuccess: () => void) => {
    set((state) => ({ ...state, loading: true, error: undefined }));
    try {
      const {
        data: { id, max },
      } = await api.Game.create(chosenNumber, get().max);
      const game: Game = {
        id,
        initialChosenNumber: chosenNumber,
        max,
        side: "creator",
      };
      set((state) => ({
        ...state,
        participatedGames: [...state.participatedGames, game],
        currentParticipatedGameId: id,
        currentGame: game,
        loading: false,
      }));
      onSuccess();
    } catch (e: any) {
      set((state) => ({
        ...state,
        error: i18n.t("ui.unknownError"),
        loading: false,
      }));
    }
  },
  fetchAndStoreGame: async (gameId: Game["id"]) => {
    set((state) => ({ ...state, loading: true, error: undefined }));
    try {
      const { data } = await api.Game.fetch(gameId);
      const game: Game = { ...data };
      const storedGame = get().participatedGames.find((g) => g.id === game.id);
      const additionalUpdates: Partial<GameStoreState> = !!storedGame
        ? {
            participatedGames: [
              ...get().participatedGames.filter((g) => g.id !== game.id),
              { ...game, side: storedGame.side },
            ],
            currentParticipatedGameId: game.id,
          }
        : {};
      console.log("Additional updates", additionalUpdates);
      set((state) => ({
        ...state,
        currentGame: game,
        loading: false,
        ...additionalUpdates,
      }));
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        set((state) => ({
          ...state,
          loading: false,
          error:
            e.response.status === 404
              ? i18n.t("gamePage.gameNotFound")
              : i18n.t("ui.unknownError"),
        }));
      }
    }
  },
  setSecondChosenNumber: async (number: number, gameId: Game["id"]) => {
    set((state) => ({
      ...state,
      loading: true,
      error: undefined,
    }));
    try {
      const { data: game } = await api.Game.addSecondChosenNumber(
        number,
        gameId
      );
      set((state) => ({
        ...state,
        participatedGames: [
          ...state.participatedGames,
          { ...game, side: "joiner" },
        ],
        currentParticipatedGameId: game.id,
        currentGame: game,
        loading: false,
      }));
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        set((state) => ({
          ...state,
          loading: false,
          error:
            e.response.status === 404
              ? i18n.t("gamePage.gameNotFound")
              : i18n.t("ui.unknownError"),
        }));
      }
    }
  },
  reset: () => {
    set((state) => ({
      ...state,
      loading: false,
      error: undefined,
      max: 10,
      currentGame: undefined,
    }));
  },
});

const useGameStore = create(
  devtools(
    persist(initialState, {
      name: "game-storage",
      getStorage: () => localStorage,
    })
  )
);

export default useGameStore;
