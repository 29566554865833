import React from "react";
import ContentBox from "../../components/ContentBox";
import PageContainer from "../../components/PageContainer";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const InfoPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bulletPoints = t("information.bulletPoints", { returnObjects: true });

  const goBack = () => {
    navigate("/");
  };

  return (
    <PageContainer>
      <ContentBox contentClassName={styles.contentBox}>
        <h1>{t("information.title")}</h1>
        <p className="mb-0">{t("information.howDoesItWork")}</p>
        <ul>
          {bulletPoints.map((bulletPoint, index) => (
            <li key={index}>{bulletPoint}</li>
          ))}
        </ul>
        <Button text={t("ui.goBack")} onClick={goBack} />
      </ContentBox>
    </PageContainer>
  );
};

export default InfoPage;
