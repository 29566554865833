import React from "react";
import styles from "./ChangeMax.module.scss";
import MinMaxNumberInput from "../../../components/MinMaxNumberInput";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import useGameStore from "../../../store/game";

interface ChangeMaxProps {
  onClose: () => void;
}

const ChangeMax: React.FC<ChangeMaxProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { setMax } = useGameStore();
  const [value, setValue] = React.useState<number | null>(null);

  const apply = () => {
    if (value === null) return;
    setMax(value);
    onClose();
  };

  const disabled = value === null || value < 2;

  return (
    <div className={styles.container}>
      <MinMaxNumberInput onNumberChange={setValue} />
      <Button text={t("ui.apply")} onClick={apply} disabled={disabled} />
    </div>
  );
};

export default ChangeMax;
