import axios, { AxiosResponse } from "axios";
import { Game } from "../store/game";

const API_ROOT =
  process.env.NODE_ENV !== "production" ? "http://localhost:3000/api" : "/api";

const axiosInstance = axios.create({
  baseURL: API_ROOT,
  timeout: 4000,
});

class Api {
  Game = {
    create: (
      chosenNumber: number,
      max: number
    ): Promise<AxiosResponse<Game>> => {
      return axiosInstance.post("/games", {
        initialChosenNumber: chosenNumber,
        max,
      });
    },
    fetch: (gameId: Game["id"]): Promise<AxiosResponse<Game>> => {
      return axiosInstance.get(`/games/${gameId}`);
    },
    addSecondChosenNumber: (
      secondChosenNumber: number,
      gameId: Game["id"]
    ): Promise<AxiosResponse<Game>> => {
      return axiosInstance.put(`/games/${gameId}`, {
        secondChosenNumber,
      });
    },
  };
}

export default new Api();
