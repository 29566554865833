import React, { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGameStore from "../../store/game";
import ErrorText from "../../components/ErrorText";
import ShowGame from "./components/ShowGame";
import ContentBox from "../../components/ContentBox";

const GamePage: React.FC = () => {
  const { t } = useTranslation();
  const { fetchAndStoreGame, loading, error } = useGameStore((state) => state);
  const params = useParams();

  useEffect(() => {
    fetchAndStoreGame(params.gameId);
  }, []);

  return (
    <PageContainer>
      <ContentBox showInfoButton={!error && !loading}>
        {loading ? (
          <h1>{t("gamePage.loading")}</h1>
        ) : (
          <>{!!error ? <ErrorText text={error} /> : <ShowGame />}</>
        )}
      </ContentBox>
    </PageContainer>
  );
};

export default GamePage;
