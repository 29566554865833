import React, { useState } from "react";
import PageContainer from "../../components/PageContainer";
import ContentBox from "../../components/ContentBox";
import { useTranslation } from "react-i18next";
import useGameStore from "../../store/game";
import ErrorText from "../../components/ErrorText";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const getCanShare = (data: any) => {
  if (typeof navigator.canShare === "function") return navigator.canShare(data);
  else return false;
};

const ShareGamePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [copied, setCopied] = useState<boolean>(false);
  const [canGoToMain, setCanGoToMain] = useState<boolean>(false);
  const { currentGame: game } = useGameStore();
  const shareData = {
    text: t("shareLink.shareText", { max: game?.max }),
    url: `https://${window.location.host}/game/${game?.id}`,
  };
  const canShare = getCanShare(shareData);

  const share = async () => {
    if (!game) return;
    try {
      setCanGoToMain(true);
      await navigator.share(shareData);
    } catch (_e) {}
  };

  const createNewGame = () => {
    navigate("/");
  };

  const copy = async () => {
    if (!game?.id) return;
    setCanGoToMain(true);
    await navigator.clipboard.writeText(shareData.url);
    setCopied(true);
  };

  return (
    <PageContainer>
      <ContentBox>
        <h1>{t("shareLink.title")}</h1>
        {!!game ? (
          <>
            <p>
              {canShare
                ? t("shareLink.pressButton")
                : t("shareLink.copyExplanation")}
            </p>
            <p className={`helper-text ${styles.helper}`}>
              {t("shareLink.tip")}
            </p>
            <div className={styles.buttonContainer}>
              {canShare ? (
                <Button text={t("shareLink.share")} onClick={share} />
              ) : (
                <Button
                  text={t("shareLink.copy")}
                  onClick={copy}
                  success={copied}
                />
              )}
              {canGoToMain && (
                <Button
                  text={t("shareLink.goToMain")}
                  onClick={createNewGame}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <ErrorText text={t("shareLink.noGame")} />
            <Button
              text={t("shareLink.createNewGame")}
              onClick={createNewGame}
            />
          </>
        )}
      </ContentBox>
    </PageContainer>
  );
};

export default ShareGamePage;
